<template>
  <div class="teacher_home_page">
    <div class="table_page">
      <div class="table_top">
        <div class="title">我的班级列表</div>
        <div class="code_block" v-show="false">
          <div class="btns">
            <div class="url_btn tab" @click="urlShow = true">
              分享链接给家长
            </div>
            <div class="code_btn tab" @click="qrcodeShow = true">
              分享二维码给家长
            </div>
          </div>
          <div class="code_title">
            请您点击上方按钮分享给家长，家长可通过访问家长端提交孩子信息（分享前请老师先创建好班级）
          </div>
        </div>
      </div>
      <el-table
        header-align="center"
        stripe
        header-cell-class-name="header_row_style"
        :data="list"
        style="width: 100%"
      >
        >
        <el-table-column
          :show-overflow-tooltip="true"
          :align="item.align || 'center'"
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :width="item.width"
          :label="item.label"
        >
        </el-table-column>
        <el-table-column
          v-if="showPda"
          :show-overflow-tooltip="true"
          align="center"
          label="PDA测试"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.pda"
              @change="(v) => selectChange(v, scope.row)"
              prop="pda"
              placeholder="请选择"
            >
              <el-option label="请选择测试类型" value=""></el-option>
              <el-option label="体质测试" value="0"></el-option>
              <el-option label="运动测试" value="1"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <div class="table_btns">
              <div class="table_btn" @click="handleClass(scope.row)">
                班级主页
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="alert" v-show="qrcodeShow">
      <div class="pop-common">
        <a
          class="layui-layer-ico layui-layer-close layui-layer-close2"
          @click="qrcodeShow = false"
          href="javascript:;"
        ></a>
        <div id="" class="layui-layer-content">
          <div class="layui-layer-wrap">
            <div class="sharePop">
              <!-- <div class="img"><img src="/index/user/qrcode.html"></div> -->
              <div class="qrcode img" id="qr" ref="qrCodeUrl"></div>
              <a class="act" @click="savePic">保存</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert" v-show="urlShow">
      <div class="pop-common">
        <a
          class="layui-layer-ico layui-layer-close layui-layer-close2"
          @click="urlShow = false"
          href="javascript:;"
        ></a>
        <div id="" class="layui-layer-content">
          <div class="layui-layer-wrap">
            <div class="sharePop">
              <div class="url" id="success_form_input">{{ url }}</div>
              <div
                class="act"
                ref="copy"
                data-clipboard-action="copy"
                data-clipboard-target="#success_form_input"
                @click="copyLink"
              >
                复制
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "teacherHome",
  components: {
    comPagination,
  },
  data() {
    return {
      copyBtn: null, //存储初始化复制按钮事件
      //班级列表
      columns: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "name",
          label: "班级名称",
        },
        {
          prop: "type",
          label: "班级",
        },
        {
          prop: "stuCount",
          label: "学员/人数",
        },
        {
          prop: "passClassCount",
          label: "上课进度",
        },
        {
          prop: "teacheList",
          label: "班级老师",
        },
      ],
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      url: "",
      qrcodeShow: false, //二维码展示
      urlShow: false, //url展示
      showPda: true,
    };
  },
  created() {},
  mounted() {
    this.copyBtn = new this.clipboard(this.$refs.copy);
    this.init();
    this.creatQrCode();
  },
  methods: {
    copyLink() {
      /*这是点击按钮触发的点击事件，关于clipboard的使用就不再赘述了，上面介绍时已经讲述过，并且使用方法在官方文档上有*/
      this.$notice({
        type: "success",
        message: "复制成功！",
      }).isShow();
    },
    //初始化
    init() {
      if (!this.$store.state.userInfo.teacherId) {
        this.showPda = false;
        this.$store.dispatch("getTeacherInfo", this.$route.query.id);
      }
      this.getMyClassList();
    },
    //生成二维码
    creatQrCode() {
      this.api.admin.getQrcodeURL().then((res) => {
        this.url = res.data;
        this.$nextTick(() => {
          setTimeout(() => {
            new QRCode(this.$refs.qrCodeUrl, {
              text: res.data,
              width: 100,
              height: 100,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          }, 500);
        });
      });
    },
    // 获取我的班级信息
    getMyClassList() {
      let data = {
        teacherId: this.$route.query.id || this.$store.state.userInfo.teacherId,
      };
      this.api.admin.findGradesOfTeacherId(data).then((res) => {
        this.list = [];
        res.data.forEach((item) => {
          let data = {
            id: item.grade.id,
            name: item.grade.name,
            schoolId: item.grade.schoolId,
            type:
              item.grade.type == 0
                ? "大班"
                : item.grade.type == 1
                ? "中班"
                : "小班",
            passClassCount: item.passClassCount || 0,
            stuCount: item.stuCount || "",
            teacheList: item.teacheList || "",
            pda: "",
            ...item,
          };
          data.stuCount = data.stuCount ? data.stuCount : 0;
          console.log("data:", data);
          this.list.push(data);
        });
      });
    },
    // 点击班级主页按钮
    handleClass(item) {
      console.log(item);
      // 0 大班  1 中班  2 小班
      item.type = item.type == "大班" ? 0 : item.type == "中班" ? 1 : 2;
      this.$router.push({
        path: "/admin/classHome",
        query: {
          ...item,
        },
      });
    },
    // select变化后跳转 todo
    selectChange(v, row) {
      // var data = {
      //   semesterId: this.$store.state.userInfo.semesterId,
      //   gradeId: row.grade.id,
      //   isEnd: 0,
      //   schoolId: row.schoolId,
      //   gradeType: row.type,
      // };
      let data = {
        schoolId: row.schoolId,
      };
      this.api.admin.eightyPercent(data).then((res) => {
        if (res.flag) {
          row.type = row.type == "大班" ? 0 : row.type == "中班" ? 1 : 2;
          this.$router.push({
            path: v == 0 ? "/admin/foundation" : "/admin/sports",
            query: {
              ...row,
              gradeId: row.grade.id,
            },
          });
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    savePic() {
      let myCanvas = document
        .getElementById("qr")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = "二维码";
      a.click();
    },

    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getMyClassList();
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_home_page {
  .btns {
    margin-top: 50px;
    @include flex(row, flex-start, center);

    .config_submit,
    .config_cancal {
      width: 80px;
      height: 20px;
      line-height: 20px;
      background: #228cec;
      color: #fff !important;
      font-size: 20px;
      font-weight: bold;
      padding: 18px 40px;
      cursor: pointer;
      margin-right: 16px;
      text-align: center;
    }
  }

  .table_top {
    @include flex(row, space-between, center);

    .title {
      font-size: 34px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #cc1a30;
      margin-bottom: 30px;
    }
  }

  .top_block {
    flex: 1;
    @include flex(row, space-between, center);

    .title {
      font-size: 18px;
      font-weight: 400;
      color: black;
      margin-bottom: 0;
    }
  }

  .qrcode {
    @include flex(column, flex-start, center);

    .btn {
      padding: 10px 20px;
    }
  }

  .alert {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    @include flex();

    .pop-common {
      border-radius: 0.15rem !important;
      border: solid 10px #cc1a30;
      width: 18%;
      background: #fff;
      position: relative;
    }

    .sharePop .url {
      color: #cc1a30;
      font-size: 0.24rem;
      white-space: pre-wrap;
      word-break: break-all;
      margin-bottom: 0.2rem;
    }

    .pop-common .layui-layer-content {
      padding: 0.4rem 0.45rem;
      font-size: 0.2rem;
      line-height: 0.35rem;
    }

    .sharePop {
      text-align: center;
    }

    .sharePop .img {
      margin-bottom: 0.2rem;
    }

    .sharePop .act {
      height: 0.1rem;
      line-height: 0.1rem;
      background: #cc1a30;
      color: #fff;
      display: inline-block;
      font-size: 0.24rem;
      font-weight: bold;
      padding: 0.2rem 0.4rem;
      margin: 0px 0.2rem;
      cursor: pointer;
    }

    .layui-layer-close2 {
      position: absolute;
      right: -20px;
      top: -20px;
      width: 36px;
      height: 32px;
      margin-left: 0;
      background-color: #fff;
      overflow: hidden;
      border-radius: 50%;
      // background-position: -146px -32px; /*no*/
      @include bg("../../../assets/img/close.png");
    }
  }

  .code_block {
    @include flex(column, flex-start, flex-end);

    .btns {
      @include flex(row, center, flex-end);

      .url_btn,
      .code_btn {
        width: 213px;
        height: 40px;
        border: 3px solid #2a3c6e;
        border-radius: 20px;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #2a3c6e;
        @include flex();
      }

      .code_btn {
        margin-left: 8px;
      }
    }

    .code_title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #383838;
      margin-top: 18px;
      margin-bottom: 12px;
    }
  }
}
</style>
